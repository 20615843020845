// Angular imports
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

@Injectable()
export class MessagingProvider {
    //modify hourly rates variables
    viewingMessageChannel: boolean = false;
    viewingMessageChannelObservable = new Subject<boolean>();

    
    constructor(
        public modalCtrl: ModalController
    ) {

    }

    async setViewingMessageChannel(viewing: boolean) {
        this.viewingMessageChannel = viewing;
        this.viewingMessageChannelObservable.next(viewing);
    }
    async getViewingMessageChannel() {
        return this.viewingMessageChannel;
    }

}
